import React from 'react';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { Box, Divider, Typography } from '@mui/material';
import { NavigationButton } from 'components/Slider/style';
import NavigationIcon from 'assets/icons/NavigationIcon';
import DatePicker, { DatePickerProps } from './DatePicker';

const CalendarNavigation = ({
  selectedDate,
  setSelectedDate,
}: DatePickerProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Box display="flex" alignItems="center" marginBottom="10px">
      <Typography variant="h2" minWidth="415px">
        {moment(selectedDate).format('dddd Do MMMM YYYY')}
      </Typography>
      <DatePicker
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
      <Divider
        orientation="vertical"
        flexItem
        sx={{
          borderColor: 'blue.900',
          mx: '12px',
        }}
      />
      <NavigationButton
        onClick={() => {
          setSelectedDate(moment(selectedDate).subtract(1, 'day'));
          if (searchParams.get('capacity') !== null)
            setSearchParams({
              capacity: searchParams.get('capacity') as string,
              spaceId: searchParams.get('spaceId') as string,
              to: moment(searchParams.get('to'))
                .subtract(1, 'day')
                .toISOString(),
              from: moment(searchParams.get('from'))
                .subtract(1, 'day')
                .toISOString(),
            });
        }}
        sx={{
          marginRight: '4px',
        }}
      >
        <NavigationIcon
          sx={{
            padding: '7px',
          }}
        />
      </NavigationButton>
      <NavigationButton
        onClick={() => {
          setSelectedDate(moment(selectedDate).add(1, 'day'));
          if (searchParams.get('capacity') !== null)
            setSearchParams({
              capacity: searchParams.get('capacity') as string,
              spaceId: searchParams.get('spaceId') as string,
              to: moment(searchParams.get('to')).add(1, 'day').toISOString(),
              from: moment(searchParams.get('from'))
                .add(1, 'day')
                .toISOString(),
            });
        }}
      >
        <NavigationIcon
          sx={{
            padding: '7px',
            transform: 'rotate(180deg)',
          }}
        />
      </NavigationButton>
    </Box>
  );
};

export default CalendarNavigation;
