import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { replace, trim } from 'lodash-es';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import Summary from 'components/SignUp/Summary';
import Promotions from 'pages/Perks/PerksListing';
import PromotionDetail from 'pages/Perks/PerkDetail';
import Home from 'pages/Home';
import Events from 'pages/Events/Events';
import EventDetail from 'pages/Events/EventDetail';
import Layout from 'components/Layout';
import Login from 'pages/Auth/Login';
import ResetPassword from 'pages/Auth/ResetPassword';
import NewPassword from 'pages/Auth/NewPassword';
import NotFound from 'pages/NotFound';
import { RequireAuth, useAuth } from 'context/AuthContext';
import MeetingRoom from 'pages/MeetingRooms';
import ExternalMeetingRooms from 'pages/ExternalMeetingRooms';
import ExternalCreditDebitCard from 'pages/ExternalMeetingRooms/ExternalCreditDebitCard';
import routes from 'utils/routes';
import BookingDetail from 'pages/MeetingRooms/BookingDetail';
import Loader from 'components/Loader';
import DesksAvailability from 'pages/Desks';
import DesksForm from 'pages/Desks/DesksForm';
import HelpFAQs from 'pages/Help/HelpFAQs';
import AccountDashboard from 'pages/Account/AccountDashboard';
import { TABS_ACCOUNT } from 'constants/tabs/tabsAccount';
import VisitorsAndDeliveries from 'pages/VisitorsAndDeliveries';
import RaiseTicket from 'pages/Help/RaiseTicket';
import Alerts from 'pages/Alerts';
import Basket from 'pages/Basket';
import Store from 'pages/Store';
import ParserFeed from 'pages/ParserFeed';
import RegisterForm from 'pages/Auth/RegisterForm';
import ChosenPlanForm from 'components/SignUp/ChosenPlanForm';
import { STEPS } from 'constants/signUp';
import ExternalRegisterForm from 'pages/Auth/ExternalRegisterForm';
import TourBooking from 'pages/TourBooking';
import Activation from 'pages/Auth/Activation';

const App = () => {
  const { pathname } = useLocation();
  const auth = useAuth();
  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'production') {
      TagManager.initialize({ gtmId: 'GTM-M9HXHF' });
    }
  }, []);
  const { isInitialized, isAuthenticated, user, currentSpaceId } = auth;
  useEffect(() => {
    if (user) {
      TagManager.dataLayer({
        dataLayer: {
          platform: 'app',
          memberID: user.id,
          spaceID: currentSpaceId,
          'member.location': trim(replace(user.space || '', 'Work.Life', '')),
        },
      });
    }
  }, [user, currentSpaceId]);

  if (
    isAuthenticated &&
    !isInitialized &&
    !STEPS.find((step) => step.route === pathname.replace(/\/$/, ''))
  ) {
    return <Loader />;
  }

  return (
    <Layout>
      <Routes>
        <Route
          path={routes.auth.signUp.index()}
          element={isAuthenticated ? <Navigate to="/" /> : <RegisterForm />}
        />

        <Route
          path={routes.auth.signIn}
          element={isAuthenticated ? <Navigate to="/" /> : <Login />}
        />
        <Route
          path={routes.auth.resetPassword}
          element={isAuthenticated ? <Navigate to="/" /> : <ResetPassword />}
        />
        <Route
          path={routes.auth.doResetPassword()}
          element={isAuthenticated ? <Navigate to="/" /> : <NewPassword />}
        />
        <Route
          path={routes.auth.activation}
          element={isAuthenticated ? <Navigate to="/" /> : <Activation />}
        />
        <Route
          path={routes.main.externalBookingsGallery()}
          element={<ExternalMeetingRooms type="gallery" />}
        />
        <Route
          path={routes.main.externalBookingsCalendar()}
          element={<ExternalMeetingRooms type="calendar" />}
        />
        <Route
          path={routes.main.externalBookingsReview()}
          element={<BookingDetail isExternal />}
        />
        <Route
          path={routes.main.externalBookingsSignup()}
          element={<ExternalRegisterForm />}
        />
        <Route
          path={routes.tourBooking}
          element={isAuthenticated ? <Navigate to="/" /> : <TourBooking />}
        />
        <Route
          path={routes.main.externalBookingsPayment}
          element={<ExternalCreditDebitCard />}
        />
        <Route
          path="*"
          element={isAuthenticated ? <NotFound /> : <Navigate to="/sign-in" />}
        />
        <Route element={<RequireAuth />}>
          <Route path={routes.main.dashboard} element={<Home />} />
          <Route
            path={routes.auth.signUp.plan()}
            element={<ChosenPlanForm />}
          />
          <Route path={routes.auth.signUp.summary} element={<Summary />} />
          <Route path={routes.main.events} element={<Events tab="events" />} />
          <Route
            path={routes.main.eventsBooked}
            element={<Events tab="booked-events" />}
          />
          <Route path={routes.event()} element={<EventDetail />} />
          <Route path={routes.main.promotions} element={<Promotions />} />
          <Route path={routes.promotion()} element={<PromotionDetail />} />
          <Route
            path={routes.main.meetingsRoomsGallery()}
            element={<MeetingRoom type="gallery" />}
          />
          <Route
            path={routes.main.meetingsRoomsCalendar()}
            element={<MeetingRoom type="calendar" />}
          />
          <Route
            path={routes.upcomingBooking()}
            element={<BookingDetail isUpcoming />}
          />
          <Route path={routes.bookings()} element={<BookingDetail />} />
          <Route path={routes.main.desks.form} element={<DesksForm />} />
          <Route
            path={routes.main.desks.charts}
            element={<DesksAvailability view="grid" />}
          />
          <Route
            path={routes.main.desks.table}
            element={<DesksAvailability view="table" />}
          />
          <Route path={routes.main.help.faq} element={<HelpFAQs tab="faq" />} />
          <Route
            path={routes.main.help.ticket}
            element={<HelpFAQs tab="ticket" />}
          />
          <Route
            path={routes.main.visitors}
            element={<VisitorsAndDeliveries tab="visitors" />}
          />

          <Route
            path={routes.main.deliveries}
            element={<VisitorsAndDeliveries tab="deliveries" />}
          />
          <Route
            path={routes.main.help.raiseTicket}
            element={<RaiseTicket />}
          />
          <Route path={routes.main.store} element={<Store />} />
          <Route path={routes.main.basket} element={<Basket />} />
          <Route path={routes.main.alerts} element={<Alerts />} />
          <Route path={routes.platform.parserFeed} element={<ParserFeed />} />
          {TABS_ACCOUNT.map((account) => (
            <Route
              key={account.label}
              path={account.value}
              element={<AccountDashboard tab={account.label} />}
            />
          ))}
          <Route path="/" element={<Navigate to={routes.main.dashboard} />} />
        </Route>
      </Routes>
    </Layout>
  );
};

export default App;
